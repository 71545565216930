@import "~bootstrap-timepicker/css/bootstrap-timepicker.css";
@import '~leaflet/dist/leaflet.css';

//estas tres definiciones son para el tema de centrar todas las columnas  las tablas del componente

/*
table.dataTable:not(.no-center-first-column) tr td {
    text-align: center !important;
}

table.dataTable thead tr th {
    text-align: center !important;
}

table.dataTable tfoot tr th {
    text-align: center !important;
}
*/

//estilos para el tema de los typeahead
.twitter-typeahead {
    width: 100%;
}

.tt-menu {
    background: white;
    border-radius: 4px;
    border: 1px solid gray;
    margin-top: 10px;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    text-align: left;
}

.tt-suggestion {
    padding: 5px 10px;
    border-bottom: 1px dotted black;
    //border-bottom: 1px dashed black;
}

.tt-suggestion:hover {
    cursor: pointer;
    color: #fff;
    background-color: #0097cf;
}

.tt-suggestion.tt-cursor {
    color: #fff;
    background-color: #0097cf;
}

.empty-message {
    padding: 5px 10px;
    text-align: center;
    //color: red;
}

/*para centrar el icono*/
.swal2-popup .swal2-icon {
    margin: 2rem auto auto auto !important;
}

/*clases para resaltar optios deshabilitados*/
select option[data-status="disabled"] {
    color: gray !important;
    font-style: italic !important;
    background-color: #f5f5f5 !important;
}

select option.disabled-option {
    font-weight: bold !important;
}
