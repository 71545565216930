/* Este elemento debe tener "position: relative" */
.is-relative{
    max-width: 420px;
    position: relative;
}
  
  /* El icono debe ser "position: absolute"
   * Ademas le damos un "display: block" y lo posicionamos */
.input-icon{
    position: absolute;
    display: block;
    top: 50%; /* Coloca el borde superior del elemento al 50% del contenedor */
    left: 7%; /* Coloca el borde izquierdo del elemento al 50% del contenedor */
    transform: translate(-50%, -50%);
    
    user-select: none;
    cursor: pointer;
}
